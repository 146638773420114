/**
 * Forms Controls for OneBid
 * @author Tomek Grzechowski <keo@becomewater.com>
 */

// material components auto initialization
import * as mdc from 'material-components-web';

$(document).ready(function() {
	mdc.autoInit();
});

// pass show/hide enabler
$(document).ready(function() {
	$('.toggle-pass').click(function() {
		var id = $(this).data('for');
		if ($(this).hasClass('toggle-on')) {
			$(this).removeClass('toggle-on');
			document.getElementById(id).type="password";
			return;
		}
		$(this).addClass('toggle-on');
		document.getElementById(id).type="text";
	});
});

// forms helper functions
window.OneForm = {
	_auto : {},
	_button : '',
	_continueErrorsProcess : {},
	_continueSubmit : false,
	_fields : {},
	_iti: null,
	_required : {},
	_rules : {},
	_rulesAsync : {},
	_rulesAsyncProcess : {},
	_rulesAsyncProcessLocks : {},
	_rulesAsyncProcessLocksCount : 0,
	_errors : {},
	_modal : false,
	_regexp : {
		email : /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
		badGMail: /@(gmai\.com|gmaiil\.com|gmail\.cim|gmail\.co|gmai\.lcom|gmail\.comp|gmail\.com\.pl|gmail\.kom|gmail\.ocm|gmail\.pl|gmail\.xom|gmaol\.com|gmeil\.com|gmiail\.com|gmial\.com|gmil\.com|gmsil\.com|gamil\.com|gmali\.com|.*\.cpm|.*\.con)$/,
		pass : /.*/,
		any: /.*/,
		phone : /^[0-9\s\+]+$/,
		url: /^http(s)?:\/\/.*/,
		numbers: /^[0-9]+$/
	},

  asyncProcessNew : function(id,msg) {
    var proc = new Date().valueOf();
    this._rulesAsyncProcessLocks[id]++;
    this._rulesAsyncProcessLocksCount++;
    this._rulesAsyncProcess[proc] = {id:id,msg:msg};
    return proc;
  },

  asyncProcessKill : function(proc) {
    var data = this._rulesAsyncProcess[proc];
    delete this._rulesAsyncProcess[proc];
    return data;
  },

  asyncProcessLockDrop : function(id) {
    this._rulesAsyncProcessLocks[id]--;
    this._rulesAsyncProcessLocksCount--;
    if (this._rulesAsyncProcessLocks[id] == 0) {
      if (this._continueErrorsProcess.hasOwnProperty(id) && this._continueErrorsProcess[id] == true) {
        this._continueErrorsProcess[id] = false;
        this.errorsProcessOne(id);
      }
    }

    if (this._rulesAsyncProcessLocksCount == 0 && this._continueSubmit == true) {
      if (this.errorsFound()) {
        this._continueSubmit = false;
        return;
      }
      $('#'+this._button).trigger('click');
    }
  },

  auto : function() {
    for (let id in this._required) {
      if(this._auto.hasOwnProperty(id)) {
        continue;
      }
      $('#'+id).change(function(){
        if ($(this).is(':checkbox')) {
          OneForm.validateCheckbox(id);
          return;
        }
        OneForm.validate(id);
      });
      this._auto[id] = true;
    }
    for (let id in this._rules) {
      if(this._auto.hasOwnProperty(id)) {
        continue;
      }
      $('#'+id).change(function(){
        OneForm.validate(id);
      });
      this._auto[id] = true;
    }
  },

  button : function(id, modal = false) {
    this._button = id;
    this._modal = modal;
  },

  error : function(id, msg) {
    if (!this._errors.hasOwnProperty(id)) {
      this._errors[id] = [];
    }
    this._errors[id].push(msg);
  },

  errorsClearOne : function(id) {
    if (!this.parentContainer(id).hasClass('mark-error')) {
      return;
    }
    $('#form-errors-for-'+id).remove();
    this.parentContainer(id).removeClass('mark-error');
  },

  errorsFound : function() {
    return Object.getOwnPropertyNames(this._errors).length > 0 ? true : false;
  },

  errorsMarkOne : function(id) {
    this.parentContainer(id).addClass('mark-error');
  },

  errorsProcessOne : function(id) {
    if(this._rulesAsyncProcessLocks.hasOwnProperty(id) && this._rulesAsyncProcessLocks[id] > 0) {
      this._continueErrorsProcess[id]=true;
      return;
    }
    if(!this._errors.hasOwnProperty(id)) {
      this.errorsClearOne(id);
      return;
    }
    this.errorsShowOne(id);
  },

  errorsShowOne : function(id) {

    if(!this._errors.hasOwnProperty(id)) {
      return;
    }

    this.errorsMarkOne(id);

    var html = '<div id="form-errors-for-'+id+'" class="form-errors"><ul>';
    this._errors[id].forEach(function(value){
      html = html + '<li>' + value + '</li>';
    });
    html = html + '</ul></div>';
    $('#form-errors-for-'+id).remove();
    this.parentContainer(id).after(html);
  },

  field : function(id) {
    if (this._fields.hasOwnProperty(id)) {
      return;
    }
    this._fields[id] = true;
    this._continueErrorsProcess[id] = false;
  },

  iti: function(id, country = "auto") {
		if (country == 'cs') {
			country = 'cz';
		} else if (country == 'el') {
			country = 'gr';
		} else if (country == 'sv') {
			country = 'se';
		} else if (country == 'uk') {
			country = 'ua';
		} else if (country == 'et') {
			country = 'ee';
		} else if (country == 'da') {
			country = 'dk';
		} else if (country == 'zh') {
			country = 'cn';
		} else if (country == 'ja') {
			country = 'jp';
		} else if (country == 'ko') {
			country = 'kr';
		} else if (country == 'en') {
			// cannot pick exact country
			country = 'auto';
		}
		var phoneinput = document.querySelector("#" + id);
	//	console.log('iti country ' + country);
		var tokens = ['b491189312426f', 'a5200bfd229915', '9c83401eb7237d', '7c3af9e4013b86', '4dbf8781d52bfc', 'ae7b0adcf80b35', 'bb10bca726c627'];
		var token = tokens[Math.floor(Math.random() * tokens.length)];
		this._iti = window.intlTelInput(phoneinput, {
			initialCountry: country,
			preferredCountries: [],
			hiddenInput: 'phonefull',
			geoIpLookup: function(success, failure) {
				$.get("https://ipinfo.io?token=" + token, function() {}, "jsonp").always(function(resp) {
					var countryCode = (resp && resp.country) ? resp.country : "";
					success(countryCode);
				});
			},
		});
		phoneinput.addEventListener("open:countrydropdown", function() {
			$('.boxcheck').addClass('invisible');
		});
		phoneinput.addEventListener("close:countrydropdown", function() {
			$('.boxcheck').removeClass('invisible');
		});
  },

	parentContainer : function(id) {
		if($('#'+id).is(':checkbox')) {
			return $('#'+id).closest('.mdc-form-field');
		}
		return $('#'+id).closest('.mdc-text-field');
	},

	required : function(id, msg) {
		this.field(id);
		this._required[id] = msg;
	},

	norequired: function(id){
		this._fields[id] =false;
		this._continueErrorsProcess[id] = true;
		this.errorsClearOne(id);
		this.removeRule(id);
		delete this._required[id];

	},

	rule: function(id, msg, call) {
		this.field(id);
		if (!this._rules.hasOwnProperty(id)) {
			this._rules[id] = [];
		}
		this._rules[id].push({msg:msg, call:call});
	},

	removeRule: function(id){
		this._rules[id]=[];
	},

	ruleAsync : function(id, msg, call) {
		this.field(id);
		if (!this._rulesAsync.hasOwnProperty(id)) {
			this._rulesAsync[id] = [];
			this._rulesAsyncProcessLocks[id] = 0;
		}
		this._rulesAsync[id].push({msg:msg,call:call});
	},

	scrollToField: function(id){
		if(this._modal) {
			$("#completeUserModal").animate({
				scrollTop: $("#"+id).offset().top-100
			}, 500);
		} else {
			$([document.documentElement, document.body]).animate({
				scrollTop: $("#"+id).offset().top-100
			}, 500);
		}
	},

	submit : function() {
		if (this._continueSubmit) {
			return true;
		}
		this._errors = {};
		
		for (let id in this._fields) {
			if ($('#'+id).is(':checkbox')) {
				this.validateCheckbox(id);
				continue;
			}
			if (id == 'phone') {
				this.validatePhone(id);
			} else {
				this.validate(id);
			}
		}
		
		if (this.errorsFound()) {
			console.log('SUBMIT erros found');
			console.log(this._errors);
			for(var field in this._errors) {
				this.scrollToField(field);
				break;
			}
			return false;
		}
		
		if (this._rulesAsyncProcessLocksCount > 0) {
			this._continueSubmit = true;
			return false;
		}
		return true;
	},

	validate : function(id) {
		if (id == 'phone') {
			this.validatePhone(id);
			this.errorsProcessOne(id);  
			return;
		}

		if (this._errors.hasOwnProperty(id)) {
			delete this._errors[id];
		}
		if (!$('#'+id).val() && !this._required.hasOwnProperty(id)) {
			this.errorsClearOne(id);
			return;
		}
		if (!$('#'+id).val()) {
			this.error(id, this._required[id]);
		}
		var _this = this;
		if (this._rules.hasOwnProperty(id)) {
			this._rules[id].forEach(function(r) {
			if (!r.call($('#'+id).val())) {
				_this.error(id, r.msg);
			}
			});
		}
		if (this._rulesAsync.hasOwnProperty(id)) {
			this._rulesAsync[id].forEach(function(r) {
				var proc = _this.asyncProcessNew(id, r.msg);
				r.call(proc, $('#'+id).val());
			});
		}
		this.errorsProcessOne(id);  
	},

	validateCheckbox : function(id) {
		if (this._errors.hasOwnProperty(id)) {  
			delete this._errors[id];
		}
		if(!$('#'+id).is(':checked')) {
			this.error(id,this._required[id]);
		}
		this.errorsProcessOne(id);
	},

	validatePhone: function(id) {
		if (this._errors.hasOwnProperty(id)) {  
			delete this._errors[id];
		}
		var val = $('#' + id).val();
		if (!val) {
			this.error(id, this._required[id]);
		}
		if (this._iti.isValidNumber()) {
			return true;
		}
		var errorCode = this._iti.getValidationError();
		if (errorCode == -99) {
			errorCode = 2;
		}
		this.error(id, this._rules[id][0].msg[errorCode]);
		this.errorsProcessOne(id);
		return false;
	},

	validateRegexp : function (val, regexp, lenmin, lenmax) {
		val = val.trim();
		if ((lenmin > 0 && val.length < lenmin) || (lenmax > 0 && val.length > lenmax)) {
			return false;
		}
		if (!regexp.test(val)) {
			return false;
		}
		return true;
	}

};
