// assets/js/site-req.js

/*
const $ = require('jquery');
global.$ = global.jQuery = $;
require('../../../vendor/twbs/bootstrap/dist/js/bootstrap.min.js');
require('../../../Resources/OneBidSiteBundle/public/js/masonry.pkgd.min.js');
*/
// require('../../../Resources/OneBidSiteBundle/public/js/jquery.fancybox.min.js');
// require('../../Resources/OneBid/SiteBundle/Resources/public/js/typeahead.bundle.new.js');
// Bloodhound = require("../../Resources/OneBid/SiteBundle/Resources/public/js/typeahead.bundle.new.js");
require('../../../Resources/OneBidSiteBundle/public/js/page.js');
require('../../../Resources/OneBidSiteBundle/public/js/onebid-forms.js');
